import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBannerFleet'
import Footer from "../components/App/Footer"
//import TestimonialsStyleOne from '../components/Testimonials/TestimonialsStyleOne'
import FleetDetail from '../components/Testimonials/trading'

const Fleet = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Fleet" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Fleet" 
            />
            {/*<TestimonialsStyleOne />*/}
            <FleetDetail />
            <Footer />
        </Layout>
    );
}

export default Fleet